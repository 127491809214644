module.exports = {
  APP_NAME: 'China Apache Hadoop Meetup 2022 上海站',
  URL_PREFIX: 'https://www.slidestalk.com', //'http://127.0.0.1',
  SYMPOSIA_MEETUP: 'symposia_meetups',
  SYMPOSIA_MEETUP_DAYS: 365,
  DEVICE_MOBILE: "mobile",
  DEVICE_DESKTOP: "desktop",
  MOBILE_HEADER_HEIGHT: '48px',
  DESKTOP_HEADER_HEIGHT: '80px',
  ENROLL_DRAWER_WIDTH: 600,
  BODY_MAX_WIDTH: '1200px'
}
