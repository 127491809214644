// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { getActiveMenu } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes: [
    layout('Front', [
      // 首页
      route('home/Index', null, '/'),
      // 直播间
      route('liveroom/Index', null, '/liveroom'),
      // 嘉宾阵容
      route('lecturer/Index', null, '/lecturer'),
      // 大会议程
      route('agenda/Index', null, '/agenda'),
      // 回放
      route('playback/Index', null, '/playback')
    ])
  ]
})

// 如果token不存在，则直接跳转登录
router.beforeEach((to, from, next) => {
  // 不需要登录的操作
  // return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
  store.dispatch('app/setWebsiteMenu', getActiveMenu(to.path))
  next()
})

export default router
