export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

export function getActiveMenu (str) {
  return str.startsWith('/') ? (str.substr(1) === '' ? 'index' : str.substr(1)) : str
}

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

// 设置cookie值
export const setCookieForSymposia = (cname, cvalue, exdays) => {
  let old_expires = getCookieForSymposia(cname)
  if (old_expires) {
    cvalue = old_expires+ ',' + cvalue
  }
  let d = new Date()
  d.setTime(d.getTime() + (exdays*24*60*60*1000))
  let expires = "expires="+d.toGMTString()
  document.cookie = cname+"="+cvalue+"; "+expires
}
// 获取cookie值
export const getCookieForSymposia = (cname) => {
  let name = cname + "="
  let ca = document.cookie.split(';')
  for(let i=0; i<ca.length; i++) {
    let c = ca[i].trim()
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}
// 根据event_id获取cookie中的token
export const getTokenByEventIdForCookie = (cname, event_id) => {
  let name = cname + "="
  let ca = document.cookie.split(';')
  let token = null
  for(let i=0; i<ca.length; i++) {
    if (!!token) {
      break;
    }
    let c = ca[i].trim()
    if (c.indexOf(name) === 0) {
      let cookie_token = c.substring(name.length, c.length)
      let cookie_tokens = cookie_token.split(',')
      let tmp_tokens;
      for(let j=0; j < cookie_tokens.length; j++) {
        tmp_tokens = cookie_tokens[j].split('|')
        if(parseInt(tmp_tokens[0]) === parseInt(event_id)) {
          token = tmp_tokens[1]
          break;
        }
      }
    }
  }
  return token
}
